import { Component, Input, OnInit } from '@angular/core';
import { promise } from 'protractor';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit {
  @Input() time: number;
  private setIntervalId: any;
  private hasTimerStarted: boolean;
  private addSecondsSound: HTMLAudioElement;
  private subtractSecondsSound: HTMLAudioElement;
  private stopTimerSound: HTMLAudioElement;
  private startTimerSound: HTMLAudioElement;
  private gameOverSound: HTMLAudioElement;

  public disabledButtons: boolean; 
  
  constructor() {
    this.hasTimerStarted = false;
    this.disabledButtons = false;
    
    this.addSecondsSound = new Audio();
    this.addSecondsSound.src = "../../../assets/media/add_seconds_sound.mp3";
    this.addSecondsSound.load();

    this.subtractSecondsSound = new Audio();
    this.subtractSecondsSound.src = "../../../assets/media/subtract_seconds_sound.mp3";
    this.subtractSecondsSound.load();

    this.stopTimerSound = new Audio();
    this.stopTimerSound.src = "../../../assets/media/stop_timer_sound.mp3";
    this.stopTimerSound.load();

    this.startTimerSound = new Audio();
    this.startTimerSound.src = "../../../assets/media/start_timer_sound.mp3";
    this.startTimerSound.load();
    this.startTimerSound.loop = true;

    this.gameOverSound = new Audio();
    this.gameOverSound.src = "../../../assets/media/game_over_sound.mp3";
    this.gameOverSound.load();
   }

  ngOnInit(): void {
    
  }

  private initTimer(): void{
    this.hasTimerStarted = true;
    this.setIntervalId = setInterval(()=>{
      this.time--;
      if(this.time <= 0){
        clearInterval(this.setIntervalId);
        this.time = 0;
        this.StopTimer();
        this.gameOverSound.play();
        this.disabledButtons = true;
      }
    },
    1000);
    this.PlaySound(this.startTimerSound);
  }

  private StopTimer(): void{
    this.StopSound(this.startTimerSound);
    this.PlaySound(this.stopTimerSound);
    this.hasTimerStarted = false;
    clearInterval(this.setIntervalId);
  }

  public startStopTimer(): void{
    if(!this.hasTimerStarted && this.time > 0)
      this.initTimer();
    else
      this.StopTimer();
  }

  public AddSecondsToTimer(seconds: number): void{
    if(seconds < 0)
      this.PlaySound(this.subtractSecondsSound);
    else
      this.PlaySound(this.addSecondsSound);    

    const calculatedTime = (this.time + seconds);
    this.time = calculatedTime <= 0 ? 0 : calculatedTime;
  }

  private StopSound(audioElement: HTMLAudioElement): void{
    audioElement.pause();
    audioElement.currentTime = 0;
  }

  private PlaySound(audioElement: HTMLAudioElement): void{
    audioElement.pause();
    audioElement.currentTime = 0;
    audioElement.play();
  }
}
